.loginDiv{
    display:flex;
    justify-content: center;
    align-items: center;
}

label{

    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: 2px 0 15px;
}
.username{
    width: max-content;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: 2px 0 15px;

}

.formDiv{
    position: absolute;
    padding: 40px 0 40px;
    top: 20%;
    background: #1C1E1F;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

}
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 5px;
    width: 80%;

}

.roomId{
    width: max-content;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: 2px 0 15px;

}

.unameDiv{
    display: flex;
    flex-direction: column;
    width: 100%;

}

.roomIdDiv{
    display: flex;
    flex-direction: column;
    width: 100%;


}

.backBtn{
    background: #0F1112;
    position: absolute;
    border: none;
    outline: none;
    left: 5%;
    top: 5%;
}

.btnDiv{
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.backBtn:hover{
    border: none;
    outline:none;
    background:none;
    color:none;
    box-shadow: none;
    border-bottom:4px solid ;
    padding-bottom: 3px ;
    color: #FFFFFF;

}

.btnImg{
    margin-right: 15%;
    width: 30%;
}

.btnText{
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: auto;
}

.joinBtn{
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%);
    color:black; */
    background: rgba(242, 170, 76, 0.01);
    color: #30C18D;
    /* background: rgba(0, 0, 0, 0.01); */
    width: 100%;
    border: 1px solid #0B754F;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 0.05em;
    outline: none;
    padding: 7px;
    /* border: none; */
    /* color:#30C18D; */
}

input{
    margin: 2px 0 15px;
    color:white;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
}

.unameInput{
    /* position: absolute;

    left: 39%;
    top: 39%; */
    width: 100%;
    text-align: center;
    height: 30%;
    padding: 2%;
    font-family: Bai Jamjuree;

}

input[type="text"]{
    font-size: 200%;
}
input:-internal-autofill-selected {
    background-color: none !important;
}

.roomIdInput{
    text-align: center;
    height: 30%;
    padding: 2%;
    text-transform:uppercase;
    font-family: Bai Jamjuree;
    /* position: absolute;
    width: 25%;
    height: 6%;
    left: 39%;
    top: 57%; */
}

input::placeholder{
    position: absolute;
    width: 468px;
    height: 44px;
    left: 727px;
    top: 431px;

    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;

}