@media only screen and (min-width: 768px) {
  .creatorName {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.05em;
    /* border-bottom:4px solid ; */
    /* border-image-slice: 1;
        border-image-source: linear-gradient(rgba(46, 191, 114, 0.9) 0%, rgba(83, 64, 211, 0.9) 100%); */
    /* padding-bottom: 3px ; */
    color: #ffffff;
  }

  .roomId {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 110%;
    letter-spacing: 0.05em;
    color: #9a9a9a;
  }

  .memTitle {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 150%;
    line-height: 30px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #ffffff;
  }

  .memName {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 100%;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .memberDisplay {
    display: flex;
    align-items: center;
    height: 40%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    word-break: break-all;
    width: 80%;
  }

  .imgndtext {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10%;
  }
  .memberImg {
    width: 20%;
    margin-right: 20%;
  }

  .leaveBtn {
    background: #0f1112;
    position: relative;
    border: none;
    outline: none;
    margin-top: 5%;
    margin-left: 2%;
  }

  .leaveBtnDiv {
    display: flex;
    align-items: flex-start;
    justify-content: left;
  }

  .leaveBtnTxt {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .fileCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 18%;
  }

  .uploadFile {
    width: 50%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 5px;
    height: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .uploadImg {
    width: 15%;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .inputFile {
    display: none;
  }

  .startPlaying {
    width: 80%;
    background: rgba(242, 170, 76, 0.01);
    color: #30c18d;
    border: 1px solid #0b754f;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 0px 12px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .fileName {
    font-size: 20px;
    color: green;
  }
  #mainContent {
    position: relative;
    margin: 5% 0 0 0 !important;
  }

  #left {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    /* border-right: 1px solid white; */
  }
  #middle {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 70vh;
    /* border-right: 1px solid white; */
  }

  #right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }

  .leaveDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .leaveBtn:hover {
    border: none;
    outline: none;
    background: none;
    color: none;
    box-shadow: none;
    border-bottom: 4px solid;
    padding-bottom: 3px;
    color: #ffffff;
  }

  .leaveImg {
    width: 17%;
    margin-right: 4%;
  }

  .leaveText {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin: auto;
  }

  .msgBoxJoinee {
    width: 60%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 5px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .msgBoxCreator {
    width: 90%;
    max-height: 70vh;
    margin-bottom: 10%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .msgContainerBase {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-flow: column-reverse;
  }
  .msgfooter {
    height: 20%;
    width: 100%;
    border-top: 1px solid white;
    background: none;
  }

  #sendBtn {
    width: 20px;
    margin-right: 5px;
  }
  .msgContainerReceived {
    display: block;
    align-items: center;
    padding-right: 20%;
    /* background-color: #001012; */
  }

  .msgContainerStatus {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
    /* background-color: #001012; */
  }

  .msgContainerSent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20%;
    /* background-color: #171410; */
  }

  .memJoinedDetails {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #414141;
    justify-content: center;
  }

  .nameSender {
    margin-right: 2%;
    margin-top: revert;
    margin-left: 2%;
    color: bisque;
  }

  .nameReceiver {
    margin-right: 3%;
    margin-top: revert;
    margin-left: 2%;
    color: cadetblue;
  }

  .msgSent {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #fff0de;
    padding: 2%;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    min-width: 50%;
    max-width: 100%;
    background-color: #171410;
    font-size: 100%;
    margin: 3% 7% 3% 0%;
    text-align: right;
    border-radius: 10px;
  }

  .msgReceived {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #e3fff8;
    padding: 2%;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    min-width: 50%;
    max-width: 100%;
    background-color: #001012;
    font-size: 100%;
    margin: 0% 0% 3% 18%;
    text-align: left;
    border-radius: 10px;
  }

  .msgStatus {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #555;
    text-align: center;
    font-size: 70%;
    width: 100%;
  }

  .msgTimestamp {
    font-size: 70%;
    color: #666;
    align-self: right;
  }

  .chatInput {
    background: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    font-size: 100% !important;
    color: white;
    padding: 5%;
  }

  .chatInput:focus {
    border: none;
    outline: none;
  }

  .chatmemimgRight {
    width: 10%;
  }
  .chatmemimgLeft {
    width: 10%;
  }

  #alertDisplay {
    position: fixed;
    right: 0;
    top: 2%;
    animation: fadeinout 3s linear forwards;
    -webkit-animation: fadeinout 3s linear forwards;
  }

  .chatAvatar {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    margin: 3% 3% 0% 5%;
    border: 1px solid black;
    clear: both;
    color: #333;
    border-radius: 50%;
    font-size: 1.5vh;
    font-weight: 900;
    letter-spacing: 0.3vh;
    line-height: 35px;
    text-align: center;
  }

  .chatName {
    margin: 3% 5% 0% 0%;
    height: 35px;
    min-height: 35px;
    line-height: 35px;
    text-align: center;
  }

  .listAvatar {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    margin: 3% 10% 3% 5%;
    clear: both;
    color: #333;
    padding: 1%;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.3vh;
    line-height: 40px;
    text-align: center;
  }

  .yellow {
    background-color: #bfa64d;
  }
  .green {
    background-color: #86bf4d;
  }
  .cyan {
    background-color: #43bbbb;
  }
  .maroon {
    background-color: #564dbf;
  }
  .magenta {
    background-color: #bf4dbb;
  }
  .pink {
    background-color: #bf4d6f;
  }
  .purple {
    background-color: #966fbd;
  }
  .blue {
    background-color: #0086bb;
  }
  .orange {
    background-color: #d17334;
  }
  .red {
    background-color: #bf4d4d;
  }

  @-webkit-keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .emojiPicker {
    position: absolute;
    top: 7%;
    left: 14%;
  }

  .emojiSection {
    width: 80%;
  }

  .defaultemoji {
    margin-top: revert;
    cursor: pointer;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 768px) {
  .creatorName {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.05em;
    /* border-bottom:4px solid ; */
    /* border-image-slice: 1;
        border-image-source: linear-gradient(rgba(46, 191, 114, 0.9) 0%, rgba(83, 64, 211, 0.9) 100%); */
    /* padding-bottom: 3px ; */
    color: #ffffff;
  }

  .roomId {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 110%;
    letter-spacing: 0.05em;
    color: #9a9a9a;
  }

  .memTitle {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 150%;
    line-height: 30px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #ffffff;
  }

  .memName {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 100%;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .memberDisplay {
    display: flex;
    align-items: center;
    height: 40%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    word-break: break-all;
    width: 70%;
  }

  .imgndtext {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10%;
  }
  .memberImg {
    width: 20%;
    margin-right: 20%;
  }

  .leaveBtn {
    background: #0f1112;
    position: relative;
    border: none;
    outline: none;
    margin-top: 5%;
    margin-left: 2%;
  }

  .leaveBtnDiv {
    display: flex;
    align-items: flex-start;
    justify-content: left;
  }

  .leaveBtnTxt {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .fileCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 18%;
  }

  .uploadFile {
    width: 50%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 5px;
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .uploadImg {
    width: 15%;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .inputFile {
    display: none;
  }

  .startPlaying {
    width: 80%;
    background: rgba(242, 170, 76, 0.01);
    color: #30c18d;
    border: 1px solid #0b754f;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 0px 12px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  .fileName {
    font-size: 20px;
    color: green;
  }
  #mainContent {
    position: relative;
    margin: 5% 0 0 0 !important;
  }

  #left {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    /* border-right: 1px solid white; */
  }
  #middle {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 30vh;
    /* border-right: 1px solid white; */
  }

  #right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }

  .leaveDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .leaveBtn:hover {
    border: none;
    outline: none;
    background: none;
    color: none;
    box-shadow: none;
    border-bottom: 4px solid;
    padding-bottom: 3px;
    color: #ffffff;
  }

  .leaveImg {
    width: 17%;
    margin-right: 4%;
  }

  .leaveText {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin: auto;
  }

  .msgBoxJoinee {
    width: 60%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 5px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .msgBoxCreator {
    width: 90%;
    max-height: 70vh;
    margin-bottom: 10%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
  }

  .msgContainerBase {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-flow: column-reverse;
  }
  .msgfooter {
    height: 20%;
    width: 100%;
    border-top: 1px solid white;
    background: none;
  }

  #sendBtn {
    width: 20px;
    margin-right: 5px;
  }
  .msgContainerReceived {
    display: block;
    align-items: center;
    padding-right: 20%;
    /* background-color: #001012; */
  }

  .msgContainerStatus {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
    /* background-color: #001012; */
  }

  .msgContainerSent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20%;
    /* background-color: #171410; */
  }

  .memJoinedDetails {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #414141;
    justify-content: center;
  }

  .nameSender {
    margin-right: 2%;
    margin-top: revert;
    margin-left: 2%;
    color: bisque;
  }

  .nameReceiver {
    margin-right: 3%;
    margin-top: revert;
    margin-left: 2%;
    color: cadetblue;
  }

  .msgSent {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #fff0de;
    padding: 2%;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    min-width: 50%;
    max-width: 100%;
    background-color: #171410;
    font-size: 100%;
    margin: 3% 7% 3% 0%;
    text-align: right;
    border-radius: 10px;
  }

  .msgReceived {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #e3fff8;
    padding: 2%;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    min-width: 50%;
    max-width: 100%;
    background-color: #001012;
    font-size: 100%;
    margin: 0% 0% 3% 18%;
    text-align: left;
    border-radius: 10px;
  }

  .msgStatus {
    margin-top: revert;
    word-break: break-word;
    word-wrap: break-word;
    color: #555;
    text-align: center;
    font-size: 70%;
    width: 100%;
  }

  .msgTimestamp {
    font-size: 70%;
    color: #666;
    align-self: right;
  }

  .chatInput {
    background: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    font-size: 100% !important;
    color: white;
    padding: 5%;
  }

  .chatInput:focus {
    border: none;
    outline: none;
  }

  .chatmemimgRight {
    width: 10%;
  }
  .chatmemimgLeft {
    width: 10%;
  }

  #alertDisplay {
    position: fixed;
    right: 0;
    top: 2%;
    animation: fadeinout 3s linear forwards;
    -webkit-animation: fadeinout 3s linear forwards;
  }

  .chatAvatar {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    margin: 3% 3% 0% 5%;
    border: 1px solid black;
    clear: both;
    color: #333;
    border-radius: 50%;
    font-size: 1.5vh;
    font-weight: 900;
    letter-spacing: 0.3vh;
    line-height: 35px;
    text-align: center;
  }

  .chatName {
    margin: 3% 5% 0% 0%;
    height: 35px;
    min-height: 35px;
    line-height: 35px;
    text-align: center;
  }

  .listAvatar {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    margin: 3% 10% 3% 5%;
    clear: both;
    color: #333;
    padding: 1%;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.3vh;
    line-height: 40px;
    text-align: center;
  }

  .yellow {
    background-color: #bfa64d;
  }
  .green {
    background-color: #86bf4d;
  }
  .cyan {
    background-color: #43bbbb;
  }
  .maroon {
    background-color: #564dbf;
  }
  .magenta {
    background-color: #bf4dbb;
  }
  .pink {
    background-color: #bf4d6f;
  }
  .purple {
    background-color: #966fbd;
  }
  .blue {
    background-color: #0086bb;
  }
  .orange {
    background-color: #d17334;
  }
  .red {
    background-color: #bf4d4d;
  }

  @-webkit-keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .emojiPicker {
    position: absolute;
    top: 7%;
    left: 14%;
  }

  .emojiSection {
    width: 80%;
  }

  .defaultemoji {
    margin-top: revert;
    cursor: pointer;
    margin-right: 2%;
  }
}
