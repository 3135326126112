@media only screen and (min-width: 768px) {
  .title {
    position: absolute;
    left: 0%;
    top: 5%;
    font-family: Audiowide;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em;
    background: linear-gradient(to right, #46dd98, #44a08d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .joinBtn {
    padding: 10px;
    max-width: 100%;
    height: 100%;
    background: rgba(242, 170, 76, 0.01);
    color: #44a08d;
    border: 1px solid #30c18d;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 155%;
    text-align: center;
    letter-spacing: 0.05em;
    outline: none;
    /* border: none; */
  }

  .createBtn {
    max-width: 100%;
    height: 100%;
    background: rgba(242, 170, 76, 0.01);
    color: #44a08d;
    padding: 10px;
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%);
        color:black; */

    /* background: rgba(0, 0, 0, 0.01); */
    border: 1px solid #30c18d;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 155%;
    text-align: center;
    letter-spacing: 0.05em;
    outline: none;
    /* border: none; */
  }

  .buttonText {
    font-size: 155%;
  }

  button:hover {
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%);
        color:black; */
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.2);
    outline: none;
    /* outline-color: rgba(255, 255, 255, 0); */
    text-shadow: 1px 1px 2px #427388;
    cursor: pointer;
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%); */
    background: linear-gradient(to right, #46dd98, #44a08d);
    color: black;
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .buttonImg {
    width: 30%;
    height: 2%;
  }

  .developersLink {
    position: relative;
    margin-top: 5%;
  }

  .extras {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heartImg {
    width: 2%;
  }

  .left {
    z-index: 1;
    height: 60%;
    width: 38%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 25%;
    left: 10%;
    border-right: 1px solid #dadada;
    border-right-style: solid;
    align-items: center;
  }

  .leftDiv {
    border-right: 1px solid white;
  }

  .right {
    z-index: 1;
    height: 60%;
    width: 38%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 25%;
    left: 48%;
  }

  .detailsTitle {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;

    color: #e7e7e7;
  }

  .btnsdiv {
    position: relative;
    margin: 15% 10% 0 10% !important;
  }

  .btnDetails {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    text-align: justify;
    max-width: 70%;
    color: #b4b4b4;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    position: relative;
    left: 10%;
    top: 10%;
    margin-top: 10%;
    font-family: Audiowide;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em;
    background: linear-gradient(to right, #46dd98, #44a08d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .joinBtn {
    padding: 10px;
    max-width: 100%;
    background: rgba(242, 170, 76, 0.01);
    color: #44a08d;
    border: 1px solid #30c18d;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 160%;
    text-align: center;
    letter-spacing: 0.05em;
    outline: none;
    /* border: none; */
  }

  .createBtn {
    max-width: 100%;
    background: rgba(242, 170, 76, 0.01);
    color: #44a08d;
    padding: 10px;
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%);
        color:black; */

    /* background: rgba(0, 0, 0, 0.01); */
    border: 1px solid #30c18d;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 160%;
    text-align: center;
    letter-spacing: 0.05em;
    outline: none;
    /* border: none; */
  }

  .buttonText {
    font-size: 100%;
  }

  button:hover {
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%);
        color:black; */
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.2);
    outline: none;
    /* outline-color: rgba(255, 255, 255, 0); */
    text-shadow: 1px 1px 2px #427388;
    cursor: pointer;
    /* background: linear-gradient(260.65deg, rgba(50, 244, 164, 0.9) 4.79%, rgba(48, 223, 201, 0.9) 52.97%, rgba(33, 109, 247, 0.9) 101.16%); */
    background: linear-gradient(to right, #46dd98, #44a08d);
    color: black;
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .buttonImg {
    width: 30%;
    height: 2%;
  }

  .developersLink {
    position: relative;
    margin-top: 5%;
  }

  .extras {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heartImg {
    width: 8%;
  }

  .left {
    z-index: 1;
    height: 60%;
    width: 38%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 25%;
    left: 10%;
    align-items: center;
    margin-bottom: 20%;
  }

  .leftDiv {
    margin-bottom: 10%;
  }

  .right {
    z-index: 1;
    height: 60%;
    width: 38%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 25%;
    left: 48%;
  }

  .detailsTitle {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #e7e7e7;
  }

  .btnsdiv {
    position: relative;
    margin: 10% 2% 0 2% !important;
  }

  .btnDetails {
    font-family: Bai Jamjuree;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    text-align: justify;
    width: 70%;
    color: #b4b4b4;
  }

  .details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 10%;
  }
}
